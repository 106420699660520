<template>
    <div class="theory-competition">
        <div class="theory-header">
            <div class="time-countdown-content">
                <div class="time-iconfont-txt"><i class="iconfont">&#xe670;</i>&nbsp;&nbsp;距离竞赛结束：</div>
                <time-count-down @change="changeTime" :left-time="leftTime"></time-count-down>
            </div>
            <div class="user-content">
                <user-logout :username="username" :avatar="avatar" width-value="200px"></user-logout>
            </div>
        </div>
        <div class="theory-competition-body">
            <theory-detail-info ref="theoryDetail" :theory-list="topicList" :paper-name="paperName" :key="key" height="100%" @goback="toIndex" @save="saveData"></theory-detail-info>
        </div>
    </div>
</template>

<script>
import UserLogout from "@/components/plugs/userLogout";
import TimeCountDown from "@/components/plugs/timeCountDown";
import TheoryDetailInfo from "@/components/plugs/theoryDetailInfo";
import {stuSaveTheoryCompetition, theoryCompetitionInfo, competitionInfo} from "@/utils/apis";
import {Loading} from "element-ui";

export default {
    name: "studentTheoryCompetition",
    components: {TheoryDetailInfo, TimeCountDown, UserLogout},
    data() {
        return {
            username: localStorage.getItem('username'),
            avatar: localStorage.getItem('headerImg'),
            endTime: this.$lockr.get('end_time'),
            leftTime: 0,
            key: 0,
            topicList: {},
            comId: this.$route.query.id ? Number(this.$route.query.id) : 0,
            topic_type: ['single_data', 'multiple_data', 'judge_data', 'blank_data', 'answer_data'],
            paperName: '',
            comType: null,
            loadingInstance: null
        }
    },
    created() {
        this.theoryDetailInfo();
        this.competitionTime()
    },
    methods: {
        changeTime(val) {
            this.leftTime = val;
            if (this.leftTime <= 0) {
                this.$message({
                    type: 'error',
                    message: '竞赛时间到，5秒后会退出当前考试',
                    duration: 5000,
                    onClose: () => {
                        localStorage.clear();
                        this.$router.push('/');
                    }
                });
            }
            if (this.leftTime === 300 && Number(this.comType) === 1) {
                this.saveData(this.$refs.theoryDetail.stuAnswerList);
            }
            if (this.leftTime === 120 && Number(this.comType) === 1) {
                this.$alert('比赛还剩2两分钟，请先保存数据后，继续作答。全部完成后记得再次保存', '温馨提示', {
                    confirmButtonText: '保存',
                    callback: action => {
                        this.saveData(this.$refs.theoryDetail.stuAnswerList)
                    }
                });
            }
        },
        competitionTime() {
            let now_time = Date.parse(new Date());
            competitionInfo().then(res => {
                if (res.code === 200) {
                    let response_time = Date.parse(new Date());
                    let process_time = (response_time - now_time) / 1000;
                    if (res.data.competition) {
                        this.comType = res.data.competition.type;
                        let competition = res.data.competition;
                        let current_time = Number(competition.time) - process_time;
                        let end_time = Number(competition.end_time);
                        let begin_time = Number(competition.begin_time);
                        if (current_time < begin_time) {
                            this.$router.push('/student/index');
                        }
                        this.leftTime = Number(end_time) - current_time;
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        },
        toIndex() {
            this.$router.push('/student/index');
        },
        saveData(data) {
            let list = [];
            data.map((item) => {
                let value = {
                    school_topic_id: item.school_theory_topic_id,
                    student_answer: item.student_answer
                }
                list.push(value);
            });
            let formdata = {
                competition_id: this.comId,
                student_answer_data: JSON.stringify(list)
            }
            this.loadingInstance = Loading.service({text: '正在保存中...',fullscreen: true})
            stuSaveTheoryCompetition(formdata).then(res => {
                if (res.code === 200) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.warning(res.msg);
                }
                this.loadingInstance.close();
            }).catch(err => {
                console.log('err', err);
                this.loadingInstance.close()
            })


        },
        theoryDetailInfo() {
            let param = {
                competition_id: this.comId
            }
            theoryCompetitionInfo(param).then(res => {
                if (res.code === 200) {
                    this.paperName = res.data.paper_name;
                    for (let key in res.data) {
                        if (this.topic_type.indexOf(key) !== -1) {
                            res.data[key].total = res.data[key].total_num;
                            if (res.data[key].total > 0) {
                                res.data[key].list.map((item) => {
                                    item.school_theory_topic_id = item.school_topic_id;
                                })
                            }
                            this.topicList[key] = res.data[key];
                        }
                    }
                    this.key++;
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                console.log('err', err);
            })
        }


    }
}
</script>

<style scoped lang="scss">
.theory-competition {
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    .theory-header {
        position: relative;
        width: 100%;
        height: 60px;
        background-color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        .time-countdown-content {
            i.iconfont {
                font-size: 18px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #E21D1A;
        }
        .user-content {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
        }
    }
    .theory-competition-body {
        width: 100%;
        height: calc(100vh - 60px);
        background-color: #F2F3FA;
    }
}

</style>