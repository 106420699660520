<template>
    <div class="stu-avatar-info" :style="{width: widthValue}">
        <el-avatar :src="headerImg" class="avatar" :size="40"></el-avatar>
        <span class="username">{{usernameValue}}</span>
        <div class="to-index" @click="toIndex"><i class="iconfont">&#xe698;</i></div>
    </div>
</template>

<script>
import {stuLogout} from "@/utils/apis";

export default {
    name: "userLogout",
    props: {
        avatar: {
            type: String,
            default: ""
        },
        username: {
            type: String,
            default: "游客"
        },
        widthValue: {
            type: String,
            default: '200px'
        }
    },
    data() {
        return {
            headerImg: this.avatar ? this.avatar : require('@/assets/images/user_img.png'),
            usernameValue: this.username
        }
    },
    methods: {
        toIndex() {
            stuLogout().then(res => {
                if (res.code) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        onClose: () => {
                            localStorage.clear();
                            this.$router.push('/');
                        }
                    });
                } else {
                    this.$router.push('/');
                }

            }).catch(err => {
                console.log(err);
            })
        }
    }
}
</script>

<style scoped lang="scss">
.stu-avatar-info {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .avatar {
        margin-right: 13px;
    }
    .username {
        color: #333333;
        font-size: 16px;
        margin-right: 15px;
    }
    .to-index {
        cursor: pointer;
        i.iconfont {
            font-size: 20px;
            color: #AAA;
        }
    }
}
</style>