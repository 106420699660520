<template>
    <div class="theory-train-detail" :style="{height: heightValue}">
        <div class="topics-list">
            <el-scrollbar ref="scrollbar" style="height: 100%;">
                <div class="theory-paper-name" v-if="paperName">
                    <span>{{paperName}}</span>
                </div>
                <div class="topic-list-ul">
                    <div class="topic-item-arr" v-for="(topic_arr, index) in topicArr" :ref="index">
                        <template v-if="topic_arr.total">
                            <div class="topic-header" :id="index">
                                {{topicIndexName(index) }}（共{{topic_arr.total}}题，共{{Number(topic_arr.total_score)}}分）
                            </div>
                            <div class="topic-item" v-for="(item, key) in topic_arr.list" :key="key">
                                <single-item :index="key" :item="item" @change="handleEvt" v-if="index === 'single_data'"></single-item>
                                <multiple-item :index="key" :item="item" @change="handleEvt" v-if="index === 'multiple_data'"></multiple-item>
                                <single-item :index="key"  :item="item" :is-show-al="false" @change="handleEvt" label="判断题" v-if="index === 'judge_data'"></single-item>
                                <fillblank-item :index="key"  :item="item" @change="handleBlanks" v-if="index === 'blank_data'"></fillblank-item>
                                <answer-item :index="key" :item="item" @change="handleAnswer" v-if="index === 'answer_data'"></answer-item>
                            </div>
                        </template>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="topic-process-box">
        </div>
        <div class="topic-process">
            <div class="topic-btn-group">
                <el-button class="topic-btn" @click="toIndex">返回</el-button>
                <el-button type="primary" class="topic-btn btn-blue" @click="saveStuData">保存</el-button>
            </div>
            <div style="height: calc(100% - 40px);">
                <topic-procee-detail :stu-data="stuAnswerList"
                                     :topic-list="topicTimeline" false-value="[]"
                                     :listen-flag="listenFlag"
                                     @jump="jumpTo"
                ></topic-procee-detail>
            </div>
        </div>
    </div>
</template>

<script>
import SingleItem from "@/components/topicComponents/topicItem/singleItem";
import common from "@/utils/common";
import MultipleItem from "@/components/topicComponents/topicItem/multipleItem";
import FillblankItem from "@/components/topicComponents/topicItem/fillblankItem";
import AnswerItem from "@/components/topicComponents/topicItem/answerItem";
import TopicProceeDetail from "@/components/topicComponents/topicProceeDetail";
export default {
    name: "theoryDetailInfo",
    components: {TopicProceeDetail, AnswerItem, FillblankItem, MultipleItem, SingleItem},
    data() {
        return {
            trainId: this.$route.query.id ? Number(this.$route.query.id) : 3,
            timesId: this.$route.query.times_id ? Number(this.$route.query.times_id) : 5,
            page: this.$route.query.page ? Number(this.$route.query.page) : 1,
            topicArr: {},
            stuData: [],
            heightValue: this.height ? this.height : '100vh',
            stuAnswerList: [],
            topicTimeline: [],
            listenFlag: [],
            scrollWrap: {},
        }
    },
    props: {
        height: String,
        theoryList: Object,
        paperName: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.theoryTopicDetail();
    },
    methods: {
        jumpTo(val) {
            let height = 0;
            for (let key in this.topicArr) {
                let clientH = this.$refs[`${key}`][0].clientHeight;
                if (key === val) {
                    break;
                } else {
                    height += clientH;
                }
            }
            this.$refs['scrollbar'].wrap.scrollTop = height;
        },
        topicIndexName(val) {
            return common.formatSortIndex(val, this.topicArr);
        },
        toIndex() {
            this.$emit('goback', '');
        },
        theoryTopicDetail() {
            this.topicArr = this.theoryList;
            for(let key in this.topicArr) {
                //处理dom节点
                if (this.topicArr[key].total) {
                    let timeline = { name: key, children: [] }
                    this.topicArr[key].list.map((item) => {
                        let stu_answer_item = {school_theory_topic_id: item.school_theory_topic_id, student_answer: '[]'};
                        let stu_answer_flag = {id: item.school_theory_topic_id, status: false};
                        if (JSON.parse(item.stu_answer).length) {
                            stu_answer_item.student_answer = item.stu_answer;
                            stu_answer_flag.status = true;
                        }
                        this.stuAnswerList.push(stu_answer_item);
                        timeline.children.push(stu_answer_flag);
                    })
                    this.topicTimeline.push(timeline);
                }

            }
        },
        handleEvt(val) {
            this.insertAnswer(val);
        },
        handleBlanks(val) {
            let options = [];
            val.label.map((item) => {
                options.push(item.value);
            });
            val.label = JSON.stringify(options);
            this.insertAnswer(val);
        },
        handleAnswer(val) {
            this.insertAnswer(val);
        },
        insertAnswer(val) {
            this.listenFlag.push(1);
            this.stuAnswerList.map((item) => {
                if (Number(item.school_theory_topic_id) === Number(val.id)) {
                    item.student_answer = val.label;
                }
            });
        },
        saveStuData() {
            this.$emit('save', this.stuAnswerList);
        }
    }
}
</script>

<style scoped lang="scss">
.theory-train-detail {
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    background-color: #F2F3FA;
    padding: 20px 21px 17px 21px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .topics-list {
        flex: 1;
        height: 100%;
        margin-right: 20px;
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .theory-paper-name {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                color: #343441;
                font-size: 16px;
            }
        }
    }
    .topic-process-box {
        height: 100%;
        width: 370px;
    }
    .topic-process {
        position: fixed;
        top: 80px;
        right: 21px;
        height: calc(100vh - 100px);
        width: 370px;
        .topic-btn-group {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            &::v-deep .topic-btn{
                flex: 1;
            }
        }
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
}
.topic-list-ul {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    .topic-item-arr {
        margin-top: 5px;
        .topic-header {
            width: 100%;
            background-color: #FFF;
            height: 57px;
            line-height: 57px;
            padding-left: 29px;
            font-weight: bold;
        }
    }
}

</style>